import 'magnific-popup';
import 'owl.carousel';
import moment from 'moment'


jQuery( document ).ready(function($) {

    $('.newsletter').on('submit', function(e) {
      e.preventDefault();
      const DATA = $(this).serialize();
    
      $.ajax({
        type: 'POST', 
        url: $(this).attr('action'),
        dataType: 'json',
        data: DATA,
        xhrFields: {
          withCredentials: false,
        },
        success: function (data) {
          $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
        },
        error: function (err) {
          console.log(err);
        }
      });
    });
    
    $('.scroll a').on('click', function(e) {
        const TARGET = $(this).attr('href');
    
        e.preventDefault();
    
        $('.nav').removeClass('open');
        $('#menu-toggle').removeClass('open');
        $('body, html').animate({
            scrollTop: $(TARGET).offset().top
        });
    });

    $('.music-carousel').owlCarousel({
        items: 3,
        dots: false,
        nav: true,
        margin: 40,
        navText: [
          "<i class='fas fa-chevron-left'></i><span class='sr'>Prev</span>",
          "<i class='fas fa-chevron-right'></i><span class='sr'>Next</span>"
        ], 
        responsive:{
          0:{ 
            items: 1
          },
          900:{
            items: 3
          }
        }
    });

    $('.merch-carousel').owlCarousel({
        loop:true,
        items: 3,
        dots: false,
        nav: true,
        margin: 30,
        mouseDrag: false,
        // touchDrag: false,
        navText: [
          "<i class='fas fa-chevron-left'></i><span class='sr'>Prev</span>",
          "<i class='fas fa-chevron-right'></i><span class='sr'>Next</span>"
        ],
        responsive:{
          0:{
            items: 1
          },
          600:{
            items: 2
          },
          900:{
            items: 3
          }
        }
    });

    $('.video-carousel').owlCarousel({
        items: 1,
        dots: false,
        nav: true,
        margin: 25,
        stagePadding: 100,
        mouseDrag: false,
        // touchDrag: false,
        navText: [
          "<i class='fas fa-chevron-left'></i><span class='sr'>Prev</span>",
          "<i class='fas fa-chevron-right'></i><span class='sr'>Next</span>"
        ],
        responsive:{
          0:{
            stagePadding: 0
          },
          900:{
            stagePadding: 100
          }
        }
    });
    $('.mfp-inline').magnificPopup(); 
    $('#menu-toggle').on('click', function(){
      $('.nav').toggleClass('open');
      $('#menu-toggle').toggleClass('open');
    })
});  